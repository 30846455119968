import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Route, Routes, useLocation, BrowserRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import AppTopBar from './AppTopbar.js';
import { EmployeeTable } from './components/EmployeeTable.js';
import { Diagnostics } from './components/Diagnostics.js';
import { UserContext } from "./UserContext.js";
import { Login } from './pages/Login.js';
// import { EmployeeMobile } from './components/EmployeeMobile';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';



const App = () => {
	const user = useContext(UserContext);
	console.log(user)
	const [rotateMenuButton, setRotateMenuButton] = useState(false);

	const [menuActive, setMenuActive] = useState(true);
	const [profileMode, setProfileMode] = useState('top');
	const [profileExpanded, setProfileExpanded] = useState(false);
	const [menuMode, setMenuMode] = useState('static');
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(true);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [topbarMenuActive, setTopbarMenuActive] = useState(false);
	const [activeTopbarItem, setActiveTopbarItem] = useState(null);
	const [darkMenu, setDarkMenu] = useState(false);
	const [theme, setTheme] = useState('blue');
	const [layout, setLayout] = useState('blue');
	const [configActive, setConfigActive] = useState(false);
	const [inputStyle, setInputStyle] = useState('outlined');
	const [ripple, setRipple] = useState(false);
	const copyTooltipRef = useRef();
	const location = useLocation();
	const [employeeList, setEmployeeList] = useState([]);
	const [uniqueDepartments, setUniqueDepartments] = useState([]);
	const [uniqueTitles, setUniqueTitles] = useState([]);
	const [loading, setLoading] = useState(true); // Loading state
	const groupAccess = process.env.REACT_APP_GROUP_ACCESS.split(",");
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
	let menuClick = false;
	let topbarItemClick = false;
	let configClick = false;

	const menu = [
		{
			label: 'Menu',
			icon: 'pi pi-fw pi-home',
			items: [
				{ label: 'All Employees', icon: 'pi pi-fw pi-home', to: '/' },
				{ label: 'Diagnostics', icon: 'pi pi-exclamation-triangle', to: '/Diagnostics' }
			]
		},
	];

	useEffect(() => {
		copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
	}, [location]);

	useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const res = await user.apiCall("GetData/" + user?.user?.userId);
                let employeeList = res.data;

                let _uniqueDepartments = [...new Set(employeeList.filter(employee => employee?.businessUnit !== null)
                    .map(employee => employee.businessUnit))];
                let _uniqueTitles = [...new Set(employeeList.filter(employee => employee.title != null)
                    .map(employee => employee.title))];

                setUniqueDepartments([..._uniqueDepartments].sort());
                setUniqueTitles([..._uniqueTitles].sort());
                setEmployeeList(employeeList);
            } catch (error) {
                console.error("Failed to fetch employee data:", error);
            } finally {
                setLoading(false); // End loading
            }
        }
        fetchData();
    }, [user]);


	const onInputStyleChange = (inputStyle) => {
		setInputStyle(inputStyle);
	};

	const onRippleChange = (e) => {
		PrimeReactContext.ripple = e.value;
		setRipple(e.value);
	};

	const onDocumentClick = () => {
		if (!topbarItemClick) {
			setActiveTopbarItem(null);
			setTopbarMenuActive(false);
		}

		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}

			hideOverlayMenu();
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		menuClick = false;
		topbarItemClick = false;
		configClick = false;
	};

	const onProfileClick = (e) => {
		setProfileExpanded(prevProfileExpanded => !prevProfileExpanded);
	};

	const onMenuClick = () => {
		menuClick = true;
	};

	const onMenuitemClick = (event) => {
		if (!event.item.items) {
			hideOverlayMenu();
		}
		if (!event.item.items && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}
	};

	const onRootMenuitemClick = () => {
		setMenuActive(prevMenuActive => !prevMenuActive);
		setRotateMenuButton((prev) => !prev);

	};

	const onMenuButtonClick = (event) => {
		menuClick = true;
		setRotateMenuButton((prev) => !prev);
		setTopbarMenuActive(false);

		if (menuMode === 'overlay') {
			setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
		}
		else {
			if (isDesktop())
				setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
			else
				setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
		}

		event.preventDefault();
	};

	const onTopbarMenuButtonClick = (event) => {
		topbarItemClick = true;
		setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);
		hideOverlayMenu();
		event.preventDefault();
	};

	const onTopbarItemClick = (e) => {
		topbarItemClick = true;

		if (activeTopbarItem === e.item)
			setActiveTopbarItem(null)
		else
			setActiveTopbarItem(e.item);

		e.originalEvent.preventDefault();
	};

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false);
		setRotateMenuButton(false);
		setStaticMenuMobileActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isMenuVisible = () => {
		if (isDesktop()) {
			if (menuMode === 'static')
				return !staticMenuDesktopInactive;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
		else {
			if (staticMenuMobileActive)
				return true;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
	};

	const onMenuModeChange = (e) => {
		setMenuMode(e.value);
		setStaticMenuDesktopInactive(false);
		setOverlayMenuActive(false);

		if (e.value === 'horizontal') {
			setProfileMode('top');
		}
	};

	const onMenuColorChange = (e) => {
		setDarkMenu(e.value);
	};

	const onProfileModeChange = (e) => {
		setProfileMode(e.value);
	};

	const onConfigClick = () => {
		configClick = true;
	};

	const onConfigButtonClick = () => {
		setConfigActive(prevConfigActive => !prevConfigActive);
		configClick = true;
	};

	const onLayoutColorChange = (color) => {
		setLayout(color);
	};

	const onThemeChange = (color) => {
		setTheme(color);
	};

	let containerClassName = classNames('layout-wrapper', {
		'menu-layout-static': menuMode !== 'overlay',
		'menu-layout-overlay': menuMode === 'overlay',
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': menuMode === 'slim',
		'menu-layout-horizontal': menuMode === 'horizontal',
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive,
		'p-input-filled': inputStyle === 'filled',
		'p-ripple-disabled': !ripple,
	});
	let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': darkMenu, 'layout-menu-container-inactive': !isMenuVisible() });

	return (
		<div className={containerClassName} onClick={onDocumentClick}>
			<div className="layout-wrapper-content">
				<AppTopBar 
					topbarMenuActive={topbarMenuActive}
					profileMode={profileMode}
					horizontal={isHorizontal()}
					activeTopbarItem={activeTopbarItem}
					rotateMenuButton={rotateMenuButton}
					onMenuButtonClick={onMenuButtonClick}
					onTopbarMenuButtonClick={onTopbarMenuButtonClick}
					onTopbarItemClick={onTopbarItemClick}
					menuActive={menuActive}
				/>
				
				{user?.userInfo?.groups?.some(group => groupAccess.includes(group)) ? (
					<div>
						<div className='layout-menu-container' onClick={onMenuClick}>
							<div className="menu-scroll-content">
								{profileMode === 'inline' && menuMode !== 'horizontal' && (
									<AppInlineProfile onProfileClick={onProfileClick} expanded={profileExpanded} />
								)}
								<AppMenu 
									model={menu} 
									menuMode={menuMode} 
									active={menuActive} 
									onMenuitemClick={onMenuitemClick} 
									onRootMenuitemClick={onRootMenuitemClick} 
								/>
							</div>
						</div>

						<div className="layout-main">
							{loading ? (
								<div>Loading...</div> // Show loading indicator
							) : (
								<Routes>
									<Route path="/" element={<EmployeeTable employeeList={employeeList} updateEmployeeList={setEmployeeList} />} />
									<Route path="/diagnostics" element={<Diagnostics originalList={employeeList} />} />
									<Route path="/login" element={<Login />} />
								</Routes>
							)}
						</div>

						<div className="layout-mask modal-in"></div>
					</div>
				) : (
					<div className="layout-main" style={{ textAlign: "center", padding: "20px" }}>
						<h1 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", marginTop:"100px" }}>
							Access Denied
						</h1>
						<p style={{ fontSize: "20px", color: "#555" }}>
							Please request access to the Data & Analytics team to proceed.
						</p>
					</div>
				)}
			</div>
		</div>
	);

}

export default App;
